import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Lightbox from '@interness/web-core/src/components/media/Lightbox/Lightbox';
export const query = graphql`
  query {
    gallery: directusMediaCollection(name: {eq: "goldschmiedekurs-ablauf"}) {
      ...LightboxGalleryGrid
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const Link = makeShortcode("Link");
const CTASection = makeShortcode("CTASection");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <h1>{`Goldschmiedkurs Ablauf`}</h1>
    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <Link to='/atelier-service/schmiedekurse' mdxType="Link">Zurück zur Kursübersicht</Link>
      <hr></hr>
      <Lightbox images={props.data.gallery.images} thumbnails={props.data.gallery.thumbnails} mdxType="Lightbox" />
    </Wrapper>
    <hr></hr>
    <CTASection mdxType="CTASection" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      